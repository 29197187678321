<template>
  <div class="campus-view1-main">
    <div class="campus-view1-content">
      <div v-for="(item,index)  in showData" :key="index" @click="item.show = !item.show">
        <el-row class="campus-view1-content-item">
          <el-col :span="20">
            <div class="campus-view1-content-item-title">
              <div style="padding: 40px 60px">
                <span class="text1">{{ item.title }}</span>
              </div>
              <div style="padding: 0px 60px 20px 60px">
                <span class="text2" v-if="!item.show">岗位职责：{{ item.info }}</span>
                <div class="text2 flex align-items-center" v-else>
                  <img src="../../../assets/images/Campus/u2741.svg" alt="">
                  <span class="ml5">岗位咨询电话： 176-6095-8313（王女士）</span>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="campus-view1-content-item-img">
              <img src="../../../assets/images/Project/01_u416.svg"/>
            </div>
          </el-col>
        </el-row>
        <div class="campus-view1-content-item-body" v-if="item.show">
          <h4>岗位职责</h4>
          <ul>
            <li v-for="(duty,index) in item.duty" :key="index">
              {{ duty }}
            </li>
          </ul>
          <h4>岗位要求</h4>
          <ul>
            <li v-for="(requirements,index) in item.requirements" :key="index">
              {{ requirements }}
            </li>
          </ul>
          <div class="go-title" v-if="item.show" @mouseover="item.show = true">
            <div>立即沟通</div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "view1",
  data() {
    return {
      active: 0,
      showData: [
        {
          title: '后端开发工程师',
          info: '负责物联网平台后台服务的开发与自测… …',
          show: false,
          duty: ['负责物联网平台后台服务的开发与自测；',
            '负责物联网平台前端界面的开发与自测；',
            '负责物联网平台数据的存储和转发；',
            '完成领导布置的其他工作；'],
          requirements: [
            '具有扎实的Java的编程基础，具有良好的编程习惯；',
            '熟悉Spring MVC、Mybatis、SpringBoot等框架；',
            '熟练使用Eclipse或IntelliJ等开发工具；',
            '熟悉各种前端技术HTML5 、CSS、JavaScript等开发语言，了解Ajax、JQeury等框架；',
            '熟悉MySQL或者SQL Server等常用数据库，熟练掌握SQL，熟悉Redis；',
            '熟悉Socket编程、HTTP/TCP/UDP协议等；',
            '熟悉XML/JSON等常见的数据封装；',
          ]
        },
        {
          title: '前端开发工程师',
          info: '负责公司前端技术架构设计、项目开发、页面重构及交互逻辑处理… …',
          show: false,
          duty: ['负责公司前端技术架构设计、项目开发、页面重构及交互逻辑处理；',
            '解决产品开发中的疑难问题，页面性能优化；',
            '梳理和优化前端开发流程，搭建高效集成的前端开发环境，制定合理有效的开发规范；',
            '开发维护前端基础工具，推动前端团队技术演进，参与规划前端团队发展；'],
          requirements: [
            '本科以上学历，计算机相关专业，三年以上网站前端开发经验；',
            '精通vuejs前端开发框架，掌握angular、react等前沿框架，精通HTML+JS+CSS等前端开发技术；',
            '熟悉webpack，gulp等打包工具的使用和原理；',
            '具有web性能分析和调优能力，了解一定的后端技术；',
            '熟悉常用算法，数据结构，设计模式，具有良好的编码风格；',
            '工作严谨细致，思路清晰，有优秀的分析问题和解决问题的能力，乐于沟通；'
          ]
        },
        {
          title: 'Android开发工程师',
          info: '根据需求设计完成Android客户端的设计、开发、集成、维护以及线上发布的相关工作… …',
          show: false,
          duty: [
            '根据需求设计完成Android客户端的设计、开发、集成、维护以及线上发布的相关工作；',
            '根据产品的需求对Android端进行架构设计；',
            '解决研发过程中遇到的技术难点，并提供技术方案；',
            '进行APP初期的模块测试，对发现的bug进行修改；',
            '将学习和研究到新的移动互联网技术应用到产品中进行创新;',
          ],
          requirements: [
            '熟悉android平台，具备2年以上的android实际开发经验，有良好的Java技术功底，能根据产品设计独立进行应用程序开发；',
            '熟练使用Android studio、Eclipse、AndroidSDK、SVN 等开发工具和SCM工具；',
            '熟练掌握Android各种UI布局和控件，能够熟练自定义UI控件,熟悉各个屏幕的适配；',
            '熟悉MVC设计模式，编程时注重软件结构设计，熟悉面向对象编程，图形界面开发；',
            '掌握SVN、GIT等代码管理工具；',
            '精通网络异步应用的开发模式, 熟悉网络通信机制，对Socket、TCP和HTTP有较深刻的理解和经验，了解3G/WiFi技术；',
            '有良好的编程习惯，代码结构清晰，命名规范；思路清晰，善于思考，学习能力强，能独立分析和解决问题；',
            '良好的沟通能力和解决问题的能力，思维敏捷，思路清晰，工作积极主动；',
            '具有IOS开发能力优先考虑。',
          ]
        },
        {
          title: '售后工程师',
          info: '负责产品的售后工作，包括产品的售前技术支持，售后问题分析和处理… …',
          show: false,
          duty: ['负责产品的售后工作，包括产品的售前技术支持，售后问题分析和处理，以及现场安装指导等相关工作；',
            '售后记录和问题分析总结，培训PPT等相关资料文档编写和相关培训视频的录制；',
            '技术应用的推广、培训；',
            '协助其他部门积极开展工作；'],
          requirements: [
            '自动化，电气，电信等相关专业；',
            '有较强的服务意识和团队合作精神；',
            '善于归纳总结客户反馈信息；',
            '有电工证，电气专业，机械经验的优先；',
            '勤奋、吃苦耐劳，能适应出差；',
          ]
        },
        {
          title: '销售经理',
          info: '负责所在区域新老客户的信息收集拜访及分析… …',
          show: false,
          duty: ['负责所在区域新老客户的信息收集拜访及分析；开拓指定行业或区域内价值客户及潜在客户，熟悉客户运作流程，与客户建立良好关系；',
            '根据公司下达的销售任务，参与制定全年及月度相应销售计划，包括拜访计划、销售目标分解计划、销售回款计划、销售预测计划等，并在销售活动中严格执行公司销售计划，完成公司的市场活动及目标；',
            '适应长期出差，区域客户关系维护，寻找与跟踪客户项目,进行商务谈判、组织与协调研发配合及项目管理推进；',
            '有效完成大区经理安排的各项指定任务及协调事宜任职资格。负责所在区域新老客户的信息收集拜访及分析；',
            '完成公司的市场活动及目标；',
          ],
          requirements: [
            '统招本科及以上学历，接受优秀的应届毕业生；',
            '适应出差，有较强的抗压能力，有责任心，团队意识；',
            '能熟练应用EXCLE表，并进行简单的公式编写；',
            '性格外向积极，有活力，服从公司安排；',
          ]
        },
        {
          title: '采购实习生',
          info: '负责完成采购目标和计划… …',
          show: false,
          duty: ['负责完成采购目标和计划；',
            '协助经理做好采购流程的控制,并提出优化建议；',
            '分析供应商市场信息，及时收集相关信息,确定短期和长期的供应商和供应渠道；',
            '负责采购合同的拟定、执行及跟进，采购物品交货期的跟踪及控制；',
          ],
          requirements: [
            '学习能力强，表格能力好；',
            '具有执行力，抗压能力；',
          ]
        },
        {
          title: '财务实习生',
          info: '负责开具销售发票… …',
          show: false,
          duty: ['负责开具销售发票；',
            '协负责库存现金和银行账户管理，每日打款、做资金日报；',
            '负责资金预算；',
            '负责银行开户设立、变更等相关工作；',
            '完成领导安排的其他工作；',
          ],
          requirements: [
            '本科及以上学历，会计、财务管理相关专业应届生，意向在财务职业长远发展；',
            '学习能力强，具有较强的责任心，主动负责，具有团队精神，善于创新，具备良好的职业道德；有相关行业实践经历者、学生干部等优先；',
            '具备敏锐的洞察能力，创新能力，良好的沟通和协同能力；',
            '具备较强的分析和总结能力；',
            '能够适应公司发展变化，服从组织安排；',
          ]
        },
      ],
    }
  },
  methods: {
    getShow(val) {
      if (this.active == 0) {
        return true
      } else {
        return this.active == val
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.campus-view1-main {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .campus-view1-content {
    width: 70%;

    .campus-view1-content-item {
      background: rgba(247, 247, 247, 1);
      margin: 20px 0px;

      .campus-view1-content-item-title {
        .text1 {
          font-size: 30px;
          font-family: 'PingFangSC-Semibold', 'PingFang SC Semibold', 'PingFang SC', sans-serif;
          font-weight: 650;
          font-style: normal;
        }

        .text2 {
          font-size: 16px;
          font-family: 'PingFangSC-Semibold', 'PingFang SC Semibold', 'PingFang SC', sans-serif;
          font-weight: 650;
          font-style: normal;
        }
      }

      .campus-view1-content-item-img {
        height: 20vh;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .campus-view1-content-item-body {
      background: rgba(247, 247, 247, 1);
      padding: 60px;
      margin-top: -18px;

      li {
        padding: 8px 0;
      }
    }
  }

  .go-title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    div {
      padding: 10px 30px;
      background: #2997FF;
      color: #FFFFFF;
      border-radius: 5px;
    }
  }
}
</style>
